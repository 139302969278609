import React from 'react';

const ResumeButton = () => {
  return (
    <a href="https://theixdev.s3.ap-southeast-2.amazonaws.com/DaleChesterResume.pdf" target="_blank" rel="noreferrer">
      <button className="group  relative flex w-fit items-center rounded-lg  border-purple-900  bg-primary px-7 py-4 leading-none ring-purple-700 hover:bg-[#020024] focus:ring-4">
        <div className="active: absolute inset-0 w-4 rounded-l-lg  bg-purple-900 transition-all duration-[250ms] ease-out group-hover:w-full group-hover:rounded-r-lg"></div>
        <span className="z-0 pl-6 text-lg text-white transition duration-200 group-hover:text-gray-100">Download Resume</span>
      </button>
    </a>
  );
};

export default ResumeButton;
