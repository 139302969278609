/*  */
import React from 'react';
import us from '../../images/about/us.png';
import github from '../../images/github.png';
import linkedin from '../../images/linkedin.png';

const AboutMeContent = () => {
  const blurb = `I am a full stack software developer with over 10 years of experience helping businesses solve technical challenges.
  
I have experience in a variety of roles, from designing backend systems to creating user-friendly front end interfaces. I enjoy the challenge of problem-solving in development and working with like-minded teams. 
  
My  preferred technologies are .Net Core and React, and I am always looking for ways to improve the user and developer experience. 
`;
  return (
    <div className="flex">
      <div className="m-auto  flex flex-col sm:flex-row  ">
        <img src={us} className="h-60 object-scale-down" alt="Me and my Family"></img>
        <div className="flex flex-col">
          <p className="font-lg whitespace-pre-wrap font-sans text-base md:text-lg">{blurb}</p>
          <div className="my-auto mt-8 flex">
            <a className="xs:w-12  w-8" href="http://www.github.com/theixdev/" rel="noreferrer" target="_blank">
              <img src={github} alt="my github link" />
            </a>
            <a className="xs:w-12 ml-8 w-8" href="https://www.linkedin.com/in/dale-chester/" rel="noreferrer" target="_blank">
              <img src={linkedin} alt="my github link" />
            </a>
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default AboutMeContent;
