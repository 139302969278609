import React from 'react';
import { motion } from 'framer-motion';

const pathVariants = {
  hidden: {
    opacity: 1,
    pathLength: 0
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 2,
      ease: 'easeInOut'
    }
  }
};

const textVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 2
    }
  }
};

const ContactDetails = () => {
  return (
    <div className="flex flex-col space-y-12 overflow-auto stroke-sky-300">
      <div className="m-auto space-y-12 pt-8">
        <a href="mailto:dale.chester@outlook.com" className="text-wh  hover:text-hi flex cursor-pointer">
          <div className="w-16  ">
            <motion.svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 350 350" initial="hidden" animate="visible" fill="#1C00ff00" className="bg-transparent  stroke-[8px]">
              <motion.path
                variants={pathVariants}
                d="M56.5 58.4c-21.8 0-39.7 17.9-39.7,39.7v150.2c0 21.8,17.9 39.7,39.7,39.7h237.7c21.8,0 39.7-17.9 39.7-39.7V98
      c0-21.8-17.9-39.7-39.7-39.7C294.1,58.4 56.5 58.4 56.5 58.4 M48 101.9 L174.1 223.6 M298 103 L174.6 222.6 M108.5 161.9 L65.1 212.9 M243.2 167.4 L285.6 212.6 z"
              />
              <motion.path variants={pathVariants} d="" />
              <motion.path variants={pathVariants} d="" />
              <motion.path variants={pathVariants} d="" />
              <motion.path variants={pathVariants} d="" />
            </motion.svg>
          </div>
          <motion.p variants={textVariants} initial="hidden" animate="visible" className="my-auto ml-6 text-base text-sky-300 sm:text-xl">
            dale.chester@outlook.com
          </motion.p>
        </a>
        <div>
          <a href="tel:0403942542" className="hover:text-hi  mb-4 flex cursor-pointer text-sky-300">
            <div className="w-16  stroke-sky-500 ">
              <motion.svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 202.592 202.592" className="bg-transparent  stroke-sky-300 stroke-[6px]" fill="#1C00ff00" initial="hidden" animate="visible">
                <g>
                  <motion.path
                    variants={pathVariants}
                    d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
              c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
              c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
              c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
              c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
              c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
              C204.079,176.661,204.265,166.343,198.048,160.105z "
                  />
                </g>
              </motion.svg>
            </div>
            <motion.p variants={textVariants} initial="hidden" animate="visible" className="my-auto ml-6 text-xl text-sky-300 sm:text-xl">
              0403 942 542
            </motion.p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
