import React from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import AboutMe from './components/AboutMe/AboutMe';
import './index.css';
import MyWork from './components/MyWork';
import ContactMe from './components/ContactMe/ContactMe';
import Footer from './components/Footer';

function App() {
  return (
    <div className="radial-gradient flex select-none flex-col justify-between text-sky-100 ">
      <Header />
      <div className=" grow ">
        <Hero />
        <AboutMe />
        <MyWork />
        <ContactMe />
      </div>
      <Footer />
    </div>
  );
}

export default App;
