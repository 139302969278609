import React from 'react';
import ResumeButton from '../ResumeButton';
import UnlockSwipe from './UnlockSwipe';

const ContactMe = () => {
  return (
    <section className="flex flex-col" id="contactmesection">
      <div className="custom-shape-divider-top-1668327721">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 0L0 0 600 40 1200 0z" className="fill-backgrounddark"></path>
        </svg>
      </div>
      <h2 className="mx-auto pb-20 text-4xl font-semibold leading-10">Contact</h2>
      <UnlockSwipe />
      <div className="mx-auto mb-28 mt-12">
        <ResumeButton />
      </div>
    </section>
  );
};
export default ContactMe;
