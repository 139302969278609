/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useRef } from 'react';
import MindsEye from './MindsEye';
import ResumeButton from './ResumeButton';
import Skills from './Skills';
import { Link } from 'react-scroll';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

const Hero = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const particlesInit = useCallback(async (engine: any) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: any) => {}, []);

  return (
    <>
      <section>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          className="absolute top-0 -z-0 h-3/4 w-full"
          options={{
            fullScreen: { enable: false },

            particles: {
              number: {
                value: isOpen ? 150 : 50,
                density: {
                  enable: true,
                  value_area: 789.1476416322727
                }
              },
              color: {
                value: isOpen ? ['#3998D0', '#2EB6AF', '#A9BD33', '#FEC73B', '#F89930', '#F45623', '#D62E32', '#EB586E', '#9952CF'] : ['#FFFFFf']
              },
              shape: {
                type: 'circle',
                stroke: {
                  width: 0,
                  color: '#000000'
                },
                polygon: {
                  nb_sides: 5
                },
                image: {
                  src: 'img/github.svg',
                  width: 100,
                  height: 100
                }
              },
              opacity: {
                value: 0.48927153781200905,
                random: false,
                anim: {
                  enable: true,
                  speed: 0.2,
                  opacity_min: 0,
                  sync: false
                }
              },
              size: {
                value: 2,
                random: true,
                anim: {
                  enable: true,
                  speed: 2,
                  size_min: 0,
                  sync: false
                }
              },
              line_linked: {
                enable: false,
                distance: 150,
                color: '#ffffff',
                opacity: 0.4,
                width: 1
              },
              move: {
                enable: true,
                speed: 0.2,
                direction: 'none',
                random: true,
                straight: false,
                out_mode: 'out',
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200
                }
              }
            },
            interactivity: {
              detect_on: 'canvas',
              events: {
                onhover: {
                  enable: true,
                  mode: 'bubble'
                },
                onclick: {
                  enable: true,
                  mode: 'push'
                },
                resize: true
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1
                  }
                },
                bubble: {
                  distance: 150,
                  size: 3,
                  duration: 3,
                  opacity: 1,
                  speed: 5
                },
                repulse: {
                  distance: 100,
                  duration: 2
                },
                push: {
                  particles_nb: isOpen ? 10 : 30,
                  duration: 1
                },
                remove: {
                  particles_nb: 2
                }
              }
            }
          }}
        />
        <div className="z-10 mx-auto grid max-w-screen-xl select-none px-4 py-8 lg:grid-cols-12 lg:gap-8 lg:pt-28 xl:gap-0">
          {/* text area */}
          <div className="my-auto mx-auto  items-center lg:col-span-7">
            <p className="mb-1 max-w-2xl pl-[2px] font-light text-blue-100 md:text-lg  lg:text-xl">Hi there!</p>
            {/* <h1 className="mb-4 max-w-2xl place-self-center text-4xl font-extrabold text-white md:text-5xl xl:text-5xl">My name is Dale</h1> */}
            <h1 className="mb-4 max-w-2xl whitespace-pre-wrap text-4xl font-bold text-blue-100 md:text-5xl lg:mb-6">
              My name is <span className="text-sky-500">Dale</span>
            </h1>
            {/* prettier-ignore */}
            <p className="mb-6 pl-[2px] max-w-2xl whitespace-pre-wrap font-light text-blue-100 md:text-2xl lg:mb-8">
                        I am a <span className='text-sky-400'>software architect</span> and <span className='text-sky-400'>interaction developer</span> with years of experience designing and developing software for business.

          </p>
            <div className="flex flex-col gap-7 pt-4 sm:flex-row">
              <Link to="aboutmesection" smooth={true} className="focus:ring-primary-300 dark:focus:ring-primary-900 z-40 mr-auto inline-flex w-fit cursor-pointer items-center rounded-lg border border-solid border-sky-900 bg-sky-700 px-10 py-3 text-center text-lg font-medium text-white hover:bg-sky-800 focus:ring-4 sm:mr-3">
                Find out more!
                <svg className="ml-2 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </Link>
              <ResumeButton />
            </div>
          </div>
          {/* image area */}
          <MindsEye isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <div className="flex py-48  px-4">
          <Skills />
        </div>
      </section>
      <div className="relative m-0">
        <div className="custom-shape-divider-bottom-1668170465 hidden sm:block ">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
          </svg>
        </div>
      </div>
    </>
  );
};

export default Hero;
