import React from 'react';
import ReactLogo from '../images/react-logo.svg';
import TailwindLogo from '../images/tailwindcss-logo.svg';
import github from '../images/github.png';
import Heart from '../images/heart.svg';
import linkedin from '../images/linkedin.png';
const Footer = () => {
  return (
    <footer id="footer" className="bg-prime w-full ">
      <div className="footer-area md: visible mx-auto flex min-h-[180px]  max-w-7xl p-8 ">
        <div className="flex flex-col">
          <div className="pb-4">
            <p className="font-hero text-hi mb-4 mr-2 inline underline">Made with</p>
            <img src={Heart} className="inline w-5 " alt="love" />
          </div>
          <div className="flex">
            <div className="tooltip">
              <span className="tooltiptext">React</span>
              <img className="xs:w-10 ml-4 w-6 md:w-10 " data-tooltip-target="tooltip-light" src={ReactLogo} alt="Made with React" />
            </div>
            <div className="tooltip">
              <span className="tooltiptext">Tailwind</span>
              <img className="xs:w-10 ml-4 w-6 md:w-10" src={TailwindLogo} alt="Made with TailwindCSS" />
            </div>
          </div>
        </div>
        <div className="my-auto ml-auto flex">
          <a className="xs:w-12 ml-4 w-10" href="http://www.github.com/theixdev/" rel="noreferrer" target="_blank">
            <img src={github} alt="my github link" />
          </a>
          <a className="xs:w-12 ml-4 w-10" href="https://www.linkedin.com/in/dale-chester/" rel="noreferrer" target="_blank">
            <img src={linkedin} alt="my github link" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
