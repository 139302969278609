/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { faInfoCircle, faUserGraduate, faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AboutMeContent from './AboutMeContent';
import EduCard from './EduCard';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import XPAccordion from './XPAccordion';

const AboutMe = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      animation.start({
        x: 0,
        transition: {
          type: 'spring',
          duration: 1.5,
          bounce: 0.3
        }
      });
    }
    if (!inView) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      animation.start({ x: '-100vw' });
    }
  }, [inView]);

  const tabs = [
    { name: 'About Me', content: <AboutMeContent />, icon: faInfoCircle, color: 'bg-sky-600' },
    { name: 'Experience', content: <XPAccordion />, icon: faCode, color: 'bg-purple-900' },
    { name: 'Education', content: <EduCard />, icon: faUserGraduate, color: 'bg-themecolor3/80' }
  ];

  //
  return (
    <div className="z-50 flex min-h-[1000px] bg-[#293548]" id="aboutmesection">
      <div ref={ref} className="mx-auto flex w-full flex-col p-1 py-48 xl:max-w-screen-xl ">
        <motion.div className="flex flex-col" animate={animation}>
          <Tab.Group>
            <Tab.List className="mx-auto">
              {tabs.map((tab) => {
                return (
                  <Tab key={tab.name} className="mx-1 rounded-t-lg  focus:outline-2 focus:outline-offset-2  focus:outline-sky-100 ">
                    <div className="relative flex items-center rounded-t-lg bg-transparent bg-foregrounddark  px-5 py-4  text-base hover:bg-background sm:text-lg  sm:font-semibold">
                      <div className={`active: absolute inset-0 w-3 rounded-t-lg  ${tab.color} outline-dash transition-all duration-[250ms] ease-out  ui-selected:w-full `}></div>
                      <FontAwesomeIcon icon={tab.icon} className="z-0 text-4xl  text-sky-100 sm:text-3xl" />
                      <span className="z-0 hidden pl-2 text-white transition  duration-200 sm:inline-flex">{tab.name}</span>
                    </div>
                  </Tab>
                );
              })}
            </Tab.List>
            <Tab.Panels>
              {tabs.map((tab) => {
                return (
                  <Tab.Panel key={tab.name} tabIndex={-1} className="tab overflow-hidden  bg-foregrounddark p-3 text-sm sm:px-5 sm:py-10 ">
                    {tab.content}
                  </Tab.Panel>
                );
              })}
            </Tab.Panels>
          </Tab.Group>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutMe;
