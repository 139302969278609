import React from 'react';
import { ReactComponent as Qutlogo } from '../../images/about/education/qut_logo.svg';
import { ReactComponent as Sbitlogo } from '../../images/about/education/SBIT_logo.svg';

interface EduItem {
  uid: number;
  qualification: string;
  school: string;
  status: string;
  icon: string;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Props = {
  [key: string]: any;
};

interface cardProps {
  eduItem?: EduItem;
}

const schools = [
  {
    uid: 0,
    qualification: 'Bachelor of Info Tech. (Computer Science)',
    school: 'Queensland University of Technology',
    year: '2020',
    status: '6.7',
    icon: 'qut'
  },
  {
    uid: 2,
    qualification: 'Diploma of Digital Media',
    school: 'Southbank Institute of Technology',
    year: '2008',
    status: '',
    icon: 'sbit'
  }
];

const EduCard = (props: cardProps, Image?: React.FunctionComponent<Props>) => {
  const displayLogo = (school: string) => {
    const displayClass = 'rounded-l-md';
    switch (school) {
      case 'qut':
        return <Qutlogo className={displayClass} />;
      case 'sbit':
        return <Sbitlogo className={displayClass} />;
      default:
        return <Qutlogo className={displayClass} />;
    }
  };

  return (
    <>
      {schools.map((eduItem, index) => (
        <div key={index} className="Educard bg-prime my-2 flex">
          <div className="m-auto h-full w-12 rounded-l-md sm:w-24 ">{displayLogo(eduItem.icon)}</div>
          <div className="mr-2 flex w-full flex-col py-4 pl-4">
            <p className="font-body mb-2 w-full text-sm sm:text-base">{eduItem.qualification}</p>
            <p className="font-body w-full text-sm opacity-50">{eduItem.school}</p>
            <div className="stats flex w-full justify-between align-middle">
              <p className={`text-left text-sm  text-green-500`}>{eduItem.status}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default EduCard;
