import React, { useRef, useState } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';

import ContactDetails from './ContactDetails';

const UnlockSwipe = () => {
  const constraintsRef = useRef(null);
  const x = useMotionValue(0);
  const xInput = [0, 100];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [swipeValue, setSwipeValue] = useState(0);
  const [isUnlocked, setIsUnlocked] = useState(false);

  const strokecolor = useTransform(x, xInput, ['hsla(39, 50, 63, 1)', 'hsla(86, 58, 65,1)']);

  const fillcolor = useTransform(x, xInput, ['hsla(39, 50, 45, 1)', 'hsla(86, 58, 35,1)']);
  const tickPath = useTransform(x, [50, 90], [0, 1]);
  const crossPathA = useTransform(x, [10, 50], [1, 0]);

  const onSwipe = () => {
    if (x.get() >= 90 && !isUnlocked) {
      setIsUnlocked(true);
    }
    if (x.get() < 90 && isUnlocked) {
      setIsUnlocked(false);
    }
  };

  return (
    <div className="h-[380px]">
      <div className="border-buttonhover bg-third mx-auto flex max-w-[180px] rounded-full border-2 shadow-inner  " ref={constraintsRef}>
        <motion.div className="example-container m-r-auto h-[72px] w-[72px] shadow-inner">
          <motion.div className="box" style={{ x }} dragDirectionLock={true} drag={swipeValue < 100 ? 'x' : 'x'} dragConstraints={constraintsRef} onDragEnd={() => onSwipe()} dragElastic={false}>
            <svg className="progress-icon" viewBox="0 0 50 50">
              <motion.path fill={fillcolor} strokeWidth="2" stroke={strokecolor} d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0" style={{ translateX: 5, translateY: 5 }} />

              <motion.path fill={fillcolor} strokeWidth="3" stroke={strokecolor} d="M14,26 L 22,33 L 35,16" style={{ pathLength: tickPath }} />

              <motion.path strokeWidth="3" fill="none" stroke={strokecolor} d="M17.4,10V6.5c0-2.9-2.3-5.2-5.2-5.2S7,3.6,7,6.5V10H4.4v12.1H20V10H17.4z M4.4,10H20" style={{ pathLength: crossPathA, translateX: 12, translateY: 12 }} />
            </svg>
          </motion.div>
        </motion.div>
      </div>
      <div className="font-poppins text-offwhite flex flex-col pt-6 text-center text-2xl font-light">
        {!isUnlocked ? 'Swipe to unlock contact details.' : <ContactDetails />}
        <p></p>
      </div>
    </div>
  );
};

export default UnlockSwipe;
