import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import logo from '../logo.svg';
import { Link } from 'react-scroll';

const Header = () => {
  const navItems = [
    ['About Me', 'aboutmesection'],
    ['Contact Me', 'contactmesection']
  ];

  const [isOpen, setIsOpen] = useState(false);

  const animation = useAnimation();

  const toggleMenu = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      animation.start({
        x: 0,
        visibility: 'visible',
        transition: {
          type: 'none'
        }
      });
    }
    if (!isOpen) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      animation.start({ x: '-100vw' }, { transition: { type: 'none' } });
    }
  }, [isOpen]);

  return (
    <header className={`top-0 z-50 h-20 select-none ${isOpen ? 'sticky bg-background sm:static sm:bg-transparent' : 'bg-transparent'}`}>
      <div className="mx-auto flex h-full max-w-screen-xl items-center justify-between px-4">
        <img src={logo} className="my-auto h-11" />
        <div className="overflow-clip">
          <nav className="hidden sm:flex">
            {navItems.map(([title, url], index) => (
              <li key={index} className="flex text-blue-200">
                <Link
                  to={url}
                  smooth={true}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className="hover-underline-animation cursor-pointer rounded-lg px-3 py-2 text-xl font-medium"
                >
                  {title}
                </Link>
              </li>
            ))}
          </nav>
          <button aria-label="toggle menu" className={`c-hamburger--shelf c-hamburger sm:hidden ${isOpen ? 'active' : ''}`} onClick={() => toggleMenu()}>
            <div className="c-hamburger-inner">
              <span className="c-hamburger-bar"></span>
              <span className="c-hamburger-bar"></span>
              <span className="c-hamburger-bar"></span>
            </div>
          </button>
          <AnimatePresence>
            <motion.nav animate={animation} className="absolute top-20 left-0 z-50 min-h-screen w-full overflow-clip bg-background pt-2 sm:hidden" exit={{ visibility: 'hidden' }}>
              {navItems.map(([title, url], index) => (
                <li key={index} className="flex cursor-pointer flex-col text-center text-blue-200 hover:bg-themecolor2/50">
                  <Link
                    to={url}
                    smooth={true}
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    className="font rounded-lg px-3 py-6 text-xl"
                  >
                    {title}
                  </Link>
                </li>
              ))}
            </motion.nav>
          </AnimatePresence>
        </div>
      </div>
    </header>
  );
};

export default Header;
