import * as React from 'react';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AccordionItemProps extends React.HTMLAttributes<Element> {
  expanded: false | number;
  setExpanded: React.Dispatch<React.SetStateAction<number | false>>;
  job: Job;
}

const data: Job[] = [
  {
    uid: 4,
    role: 'Software Developer',
    tasks: ['Collaborating with a talented and passionate team to create a suite of innovative specialist products.'],
    years: '2022-Present',
    skills: ['.NET', 'C#', 'React', 'Azure', 'NextJS', 'Docker'],
    company: 'FuseWork'
  },
  {
    uid: 3,
    role: 'Software Architect',
    tasks: ['Partnered with a team of highly skilled consultants to drive impressive results for our clients.', 'Contributed to the development of best practices guidelines and standards for the industry.', 'Gained proficiency in range of front and back end technologies.  '],
    years: '2021-2022',
    skills: ['.NET', 'C#', 'Blazor', 'React', 'Azure', 'Gatsby'],
    company: 'SSW'
  },
  {
    uid: 2,
    role: 'Developer',
    tasks: [
      'Worked with  small team to create bill management solution.',
      'Integrated K2 Business Process Manager with a number of technologies and 3rd party software to create efficient business processes.',
      'Developed in all aspects of the application including SQL Database, ORM, services and K2 Smartforms UI.',
      'Wrote reports for customers as well as internal users, including prediction algorithms for budgeting.'
    ],
    years: '2015-2020',
    skills: ['.NET', 'C#', 'Entity Framework', 'SQL', 'K2 BPM', 'WCF Services', 'Azure Devops', 'HTML'],
    company: 'Bill Butler'
  },
  {
    uid: 1,
    role: 'Developer',
    tasks: ['Worked with stakeholders to scope and develop software', 'Created extensive Windows Form UI and SQL database.', 'Developed software to meet government legislative requirements and user needs.', 'Created required reports for software as well as a system to allow users to create, save and share their own mail merge reports for clients.'],
    years: '2008-2015',
    skills: ['.NET Framework', 'SQL', 'DevXpress Reports'],
    company: 'Debt Cutter'
  }
];

const AccordionItem = (props: AccordionItemProps) => {
  const { job } = props;
  const isOpen = props.job.uid === props.expanded;

  return (
    <>
      <motion.button className={`AccordianItem my-1 flex cursor-pointer select-none justify-center overflow-hidden text-ellipsis rounded-lg p-4 py-2 text-base ${isOpen ? '' : ' border border-sky-500/20 shadow-lg hover:bg-sky-900/20'} `} initial={false} animate={false} onClick={() => props.setExpanded(isOpen ? false : job.uid)}>
        <p className="hidden text-clip break-words text-left sm:inline sm:w-36">
          {job.role} <br />
          <span className="w-full text-left opacity-75 ">{job.years}</span>{' '}
        </p>

        <div className="my-auto">
          <span className="text-hi my-auto  ml-2 whitespace-pre font-semibold text-sky-400 sm:ml-5"> @ {job.company}</span>
        </div>
        <motion.div className="my-auto ml-auto mr-1 h-6 w-6 md:mr-12" key="chevron1" animate={isOpen ? { rotate: 90, transition: { type: 'spring' } } : { rotate: 0, transition: { type: 'spring' } }}>
          <FontAwesomeIcon icon={faChevronRight} className="h-6 w-6" />
        </motion.div>
      </motion.button>
      <AnimatePresence initial={true} exitBeforeEnter>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto', transition: { type: 'spring' } },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className={`AccordionCard content-div flex flex-col `}>
              <div className="flex flex-wrap py-2 px-2">
                {job?.skills?.map((skill, index) => {
                  return (
                    <div key={`skill_${index}`} className="mr-2 mb-2 w-auto rounded-full bg-pink-900 py-1 px-4 font-sans text-white shadow-md ">
                      <span></span>
                      {skill}
                    </div>
                  );
                })}
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

const XPAccordion = () => {
  const [expanded, setExpanded] = useState<false | number>(false);
  const jobs = data;
  return (
    <div className="xp-cards  flex min-h-full w-full flex-col ">
      {jobs.map((job: Job, index) => (
        <AccordionItem key={index} expanded={expanded} setExpanded={setExpanded} job={job} />
      ))}
    </div>
  );
};

export default XPAccordion;
