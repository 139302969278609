/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ReactComponent as Open } from '../open.svg';
import { ReactComponent as Close } from '../close.svg';
interface MindsEyeProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

const MindsEye = (props: MindsEyeProps) => {
  const { isOpen, setIsOpen } = props;

  const toggleOpen = () => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    setIsOpen(!isOpen);
  };

  return (
    <div className="z-40 mx-auto mt-8 bg-primary  lg:col-span-5 lg:ml-auto lg:mr-14 lg:mt-0 lg:flex">
      {isOpen ? (
        <Open
          className="z-50 m-auto w-64 cursor-pointer duration-700 "
          onClick={() => {
            toggleOpen();
          }}
        />
      ) : (
        <Close
          className="z-50 m-auto w-64 cursor-pointer duration-700 "
          onClick={() => {
            toggleOpen();
          }}
        />
      )}
    </div>
  );
};

export default MindsEye;
