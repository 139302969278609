import React, { useState, useEffect } from 'react';
import typescript from '../images/skills/skill-typescript.svg';
import csharp from '../images/skills/skill-cs.svg';
import react from '../images/skills/skill-react.svg';
import sql from '../images/skills/skill-sql.svg';
import dotnet from '../images/skills/skill-dotnet.svg';
const Skills = () => {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setExpand(true);
  }, []);

  const toggleExpand = () => {
    setExpand((state) => !state);
  };

  return (
    <section className="mx-auto flex h-32 gap-4 bg-transparent text-sky-100" onClick={() => toggleExpand()}>
      <h2 className="z-40 cursor-pointer">Skills</h2>
      <div className={`logo hideme ${expand ? 'show' : ''} w-48"`}>
        <img src={typescript} className="w-20" alt="typescript" />
      </div>
      <div className={`logo hideme ${expand ? 'show' : ''} w-48"`}>
        <img src={csharp} className="w-20" alt="typescript" />
      </div>
      <div className={`logo hideme ${expand ? 'show' : ''} w-48"`}>
        <img src={react} className="w-20" alt="typescript" />
      </div>
      <div className={`logo hideme ${expand ? 'show' : ''} w-48"`}>
        <img src={sql} className="w-20" alt="typescript" />
      </div>
      <div className={`logo hideme ${expand ? 'show' : ''} w-48"`}>
        <img src={dotnet} className="w-20" alt="typescript" />
      </div>
    </section>
  );
};

export default Skills;
